import { Color } from "../enums/Color";

export const jobOrganizationCard = {
    mobile: {
        height: "auto",
        background: Color.LightAsh,
        borderRadius: "2.41vw",
        logoWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            padding: "4.83vw 3.62vw 0 3.62vw"
        },
        rowWrapper: {
            padding: "3.62vw 3.62vw 0 3.62vw",
            alignItems: "center" as "center"
        },
        tagWrapper: {
            display: "flex",
            flexDirection: "row" as "row"
        },
        columnWrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            marginLeft: "5.56vw",
        },
        logo: {
            width: "18.11vw",
            height: "18.11vw",
            background: Color.White,
            borderRadius: "1.2vw",
            objectFit: "contain",
            cursor: "pointer"
        },
        name: {
            fontSize: "3.86vw",
            fontWeight: "bold" as "bold",
            color: Color.Black,
            paddingTop: "2.03vh"
        },
        count: {
            fontSize: "3.06vw",
            fontWeight: "bold" as "bold",
            color: Color.Black,
        },
        jobPostsTxt: {
            fontSize: "2.66vw",
            color: Color.Black
        },
        tag: {
            height: "6.04vw",
            padding: "0 3.62vw",
            borderRadius: "6.03vw",
            marginRight: "2.41vw",
            marginLeft: "-1vw",
            background: Color.White,
            fontSize: "2.65vw",
            color: Color.DeepAsh,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        tagIcon: {
            fill: Color.DeepAsh,
            width: "2.89vw",
            marginRight: "1.38vw"
        },
        button: {
            width: "7.729vw",
            height: "7.729vw",
            marginRight: "1.39vw",
            marginTop: "3.50vw",
            backgroundColor: Color.MiddleAsh,
            border: "none",
            borderRadius: "50%",
            padding: "0"
        },
        icon: {
            fill: Color.MiddleBlack,
            width: "3.89vw",
            height: "3.89vw",
            display: "block",
            margin: "auto",
            overflow: "visible",
            address: {
                margin: "unset",
                marginRight: "2.80vw",
                marginLeft: "0.60vw"
            },
            disableIcon: {
                margin: "unset",
                fill: Color.DarkRed,
                width: "5vw",
                height: "5vw",
                marginTop: "3vw"
            }
        },
        addressWrapper: {
            display: "flex",
            color: Color.DeepAsh,
            marginTop: "3.50vw",
            fontSize: "3.38vw",
        },
        iconWrapper: {
            display: "flex",
            gap: "3.5vw"
        },
        startupSL: {
            width: "17vw",
            height: "5vw",
            marginTop: "3vw"
        },
        rainbowSL: {
            width: "5vw",
            height: "5vw",
            marginTop: "3vw"
        },
        line: {
            margin: "3.62vw 3.62vw 0 3.62vw",
            color: Color.MiddleAsh,
            backgroundColor: Color.MiddleAsh,
            border: "0.24vw solid" + Color.MiddleAsh 
        },
        description: {
            fontSize: "3.38vw",
            color: Color.DeepAsh,
            lineHeight: "5.4vw"
        },
        favouriteButton: {
            position: "relative",
            width: "100%",
            height: "12.07vw",
            fontSize: "4.34vw",
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "2.41vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            icon: {
                stroke: Color.White,
                width: "4.85vw",
                height: "1.85vh",
                marginLeft: "1.8vw"
            }
        },
        notifyButton: {
            position: "relative",
            width: "11.11vw",
            marginLeft: "auto",
            height: "11.11vw",
            border: "none",
            borderRadius: "50%",
            backgroundColor: Color.White,
            fill: Color.DeepAsh,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            icon: {
                width: "6vw",
                height: "6vw"
            },
            hover: {
                backgroundColor: Color.LightGreen,
                fill: Color.Green
            }
        },
    },
    desktop: {
        width: "20.72vw",
        marginLeft: "auto",
        marginRight: "0.00vw",
        marginTop: "3.20vh",
        borderRadius: "0.52vw",
        description: {
            fontSize: "0.77vw",
            marginBottom: "1.30vw",
            lineHeight: "1.2vw"
        },
        favouriteButton: { 
            width: "14.687vw",
            height: "2.604vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw",
            margin: "0 auto",
            icon: {
                width: "1.25vw",
                height: "2.59vh",
                marginLeft: "0.5vw"
            }
        },
        logoWrapper: {
            padding: "1.04vw" 
        },
        rowWrapper: {
            padding: "0.3vh 1.04vw"
        },
        columnWrapper: {
            marginLeft: "1.40vw"
        },
        logo: {
            width: "3.9vw",
            height: "3.9vw",
            borderRadius: "0.3vw"
        },
        name: {
            fontSize: "1.02vw",
            paddingTop: "1vw",
            whiteSpace: "nowrap", 
            overflow: "hidden",
            width: "12vw",
            textOverflow: "clip" 
        },
        count: {
            fontSize: "0.93vw"
        },
        text: {
            fontSize: "0.77vw",
            marginTop: "0.42vh"
        },
        jobPostsTxt: {
            fontSize: "0.93vw"
        },
        tag: {
            height: "1.82vw",
            padding: "0 1.04vw",
            marginRight: "0.78vw",
            borderRadius: "1.3vw",
            fontSize: "0.78vw",
            marginLeft: "0"
        },
        tagIcon: {
            width: "0.7vw",
            marginRight: "0.36vw"
        },
        button: {
            width: "1.66vw",
            height: "1.66vw",
            marginRight: "0.35vw",
            marginTop: "1.04vw"
        },
        icon: {
            width: "0.83vw",
            height: "0.83vw",
            address: {
                marginRight: "0.60vw",
                marginLeft: "0.20vw"
            },
            disableIcon: {
                width: "1.2vw",
                height: "1.2vw",
                marginTop: "1vw"
            }
        },
        addressWrapper: {
            marginTop: "1.04vw",
            fontSize: "0.78vw"
        },
        iconWrapper: {
            gap: "0.75vw"
        },
        startupSL: {
            width: "4.5vw",
            height: "1.2vw",
            marginTop: "1vw"
        },
        rainbowSL: {
            width: "1.2vw",
            height: "1.2vw",
            marginTop: "1vw"
        },
        line: {
            border: "0.01vw solid" + Color.MiddleAsh,
            margin: "1.04vw 1.04vw"
        },
        notifyButton: {
            width: "6vh",
            height: "6vh",
            marginLeft: "0.78vw",
            icon: {
                width: "3vh",
                height: "3vh"
            }
        }
    }
}