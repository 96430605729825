import React, { useContext, useEffect, useState } from 'react';
import { MenuItem } from '../../models/MenuItem';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import { Button } from './Buttons/Button';
import { Dropdown } from './Dropdown';
import { Recruiter } from '../../models/Recruiter';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {
    open: boolean;
    toggle(): void;
    recruitersLogin(): void;
    jobSeekersLogin(): void;
    menuItems: MenuItem[];
    candidate: LoggedCandidate | null;
    recruiter: Recruiter | null;
    candidateProfileItems: any[];
    profilePictureURL?: string;
}

export const MobileMenuDrawer: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("mobileMenu")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {   
        setStyles(styleContext.getComponentStyle('mobileMenu'));                 
    }, [isMobile]);
    
    return (
        <>
            {/* {This is back layer of the drawer} */}
            <div style={{ ...styles.layer, ...(props.open && styles.layer.open) }} onClick={props.toggle}>
            </div>
            <div style={{...styles.wrapper, ...(props.open ? styles.open : styles.close)}}>
                <Button type="button" style={styles.closeButton} onClick={props.toggle}>
                    <Close style={styles.closeIcon} />
                </Button>
                <div>
                    <ul style={styles.menu}>
                        {props.menuItems.map((menuItem, index) => <li key={index} style={styles.menuItem}>
                            {menuItem.isList ? <Dropdown styles={styles.dropdown} items={menuItem.items!}><span>{menuItem.label!}</span></Dropdown> : menuItem.item}
                        </li>
                        )}
                    </ul>
                </div>
                {props.candidate && <div>
                    <Dropdown styles={styles.userDropdown} items={props.candidateProfileItems}>
                        <img src={props.profilePictureURL} style={styles.userAvatar} /> {props.candidate.forename}
                    </Dropdown>
                </div>}
                {!props.candidate && !props.recruiter && <div>
                    <Button type="button" style={styles.jobSeekersButton} onClick={props.jobSeekersLogin}>{"JOB SEEKERS LOGIN"}</Button>
                    <Button type="button" style={styles.recruitersButton} onClick={props.recruitersLogin}>{"RECRUITERS LOGIN"}</Button>
                </div>}
            </div>
        </>
    );
}