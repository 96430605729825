import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const verticalJobDetailCard = {
    mobile: {
        height: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-4.05vh",
        background: Color.White,
        borderRadius: "2.78vw",
        paddingBottom: "2.79vh",
        boxShadow: "5px 5px 45px #00000029",
        zIndex: "2",
        position: "relative" as "relative",
        wrapper: {
            display: "flex",
            flexDirection: "row",
            padding: "1.11vh 4.83vw 0 4.83vw",
            alignItems: "center",
            Color: Color.MiddleBlack
        },
        unalignedWrapper: {
            alignItems: "unset"
        },
        logo: {
            width: "18.11vw",
            height: "18.11vw",
            background: Color.White,
            borderRadius: "1.39vw",
            marginTop: "-9.05vw",
            opacity: "1",
            objectFit: "contain",
            cursor: "pointer"
        },
        title: {
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "3.86vw",
            letterSpacing: "0px",
            color: Color.Black,
            opacity: "1",
        },
        jobSaved: {
            display: "flex",
            alignItems: "center",
            color: Color.Blue,
            fontSize: "3.86vw",
            marginLeft: "1.2vw",
            icon: {
                width: "3vw",
                height: "4vw",
                fill: Color.Blue,
                marginRight: "1.2vw"
            }
        },
        organization: {
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            marginTop: "calc(1.20vw - 1.11vh)",
            fontSize: "3.89vw",
            letterSpacing: "0px",
            color: Color.Black,
            opacity: "1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            fontWeight: "normal"
        },
        emptyTag: {
            marginLeft: "auto"
        },
        urgentTag: {
            width: "15.7vw",
            height: "6.03vw",
            marginLeft: "1.39vw",            
            borderRadius: "6.038vw",
            background: Color.Red,
            fontSize: "2.17vw",
            color: Color.AliceBlue,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent:"center"
        },
        videoTag: {
            width: "15.7vw",
            height: "6.03vw",
            borderRadius: "6.94vw",
            background: Color.Blue,
            fontSize: "2.17vw",
            color: Color.White,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            cursor: "pointer"
        },
        icon: {
            width: "3.89vw",
            height: "3.14vw",
            marginLeft: "2.78vw",
            marginTop: "-1.08vh",
            fill: Color.Red,
        },
        text: {
            fontSize: "2.65vw",
            color: Color.DeepAsh,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textSpaced: {
                marginLeft: "3.62vw"
            }
        },
        remote: {
            marginLeft: "3.39vw",
            display: "flex",
            alignItems: "center"
        },
        smallIcon: {
            marginRight: "1.39vw",
            width: "2.78vw",
            height: "3.14vw",
            fill: Color.MiddleBlack,
            marginBottom: "-0.4vw"
        },
        briefcaseIcon: {
            marginBottom: "-0.3vw",
            marginRight: "2vw"
        },
        line: {
            backgroundColor: Color.MiddleAsh,
            margin: "4.95vw 4.83vw 1.5vw 4.83vw",
            border: "1px solid" + Color.MiddleAsh 
        },
        summaryButtonWrapper: {
            padding: "0 4.83vw"
        },
        summaryButton: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "10.86vw",
            marginTop: "7.24vw",
            marginLeft: "auto",
            marginRight: "auto",
            color: Color.Black,
            fontWeight: "bold",
            backgroundColor: Color.LightAsh,
            border: "none",
            borderRadius: "1.2vw",
            padding: "0 3.62vw",
            boxSizing: "border-box",
            iconWrapper: {
                marginLeft: "auto"
            },
            icon: {
                width: "4.10vw",
                height: "4.5vw",
                fill: Color.Black,
                transition: "transform .2s ease-in-out"
            },
            expandIcon: {
                transform: "rotate(180deg)"
            }
        },
        overview: {
            fontSize: "3.38vw",
            lineHeight: "5.43vw",
            Color: Color.MiddleBlack,
            marginBottom: "4.83vw"
        },
        flippingWrapper: {
            display: "flex",
            flexDirection: "column-reverse"
        },
        summary: {
            display: "flex",
            flexDirection: "column",
            padding: "1.35vh 4.83vw 0 4.83vw",
            fontSize: "3.38vw",
            color: Color.MiddleBlack,
            item: {
                display: "flex",
                flexDirection: "row",
                marginLeft: "2.41vw"
            },
            key: {
                width: "35%"
            },
            value: {
                fontWeight: "bold",
                borderLeft: "2px solid " + Color.MiddleAsh,                
                padding: "0 0 3.62vw 4.83vw",
                maxWidth: "50%" 
            },
            lastValue: { 
                paddingBottom: "0"
            }
        },
        share: {
            wrapper: {
                paddingLeft: "4.83vw",
                PaddingRight: "4.83vw"
            },
            button: {
                width: "8.33vw",
                height: "8.33vw",
                marginRight: "1.39vw",
                backgroundColor: Color.MiddleAsh,
                borderRadius: "50%",
                padding: "1.69vw 2.25vw"
            },
            icon: {
                fill: Color.MiddleBlack,
                width: "3.89vw",
                height: "3.89vw",
                display: "block"
            },
        },
        apply: {
            wrapper: {
                display: "flex",
                flexDirection: "column"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                paddingTop: "2.41vw",
                paddingLeft: "3.62vw",
                paddingRight: "3.62vw",
                alignItems: "center" as "center"
            },
            applyButton: {
                position: "relative",
                width: "100%",
                height: "12.07vw",
                color: Color.White,
                fontWeight: "bold",
                backgroundColor: Color.Green,
                border: "none",
                borderRadius: "2.41vw",
                fontSize: "4.34vw"
            },
            saveButton: {
                position: "relative",
                width: "100%",
                height: "12.07vw",
                color: Color.Blue,
                fontWeight: "bold",
                backgroundColor: "transparent",
                borderRadius: "2.41vw",
                borderColor: Color.Blue,
                border: "0.72vw solid",
                fontSize: "4.34vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            saveIcon: {
                fill: Color.Blue,
                marginLeft: "1.2vw",
                width: "3.86vw",
                height: "4.58vw",
            },
            notifyButton: {
                position: "relative",
                width: "11.11vw",
                marginLeft: "auto",
                height: "11.11vw",
                backgroundColor: Color.MiddleAsh,
                border: "none",
                borderRadius: "50%",
                fill: Color.MiddleBlack,
                hover: {
                    backgroundColor: Color.LightGreen,
                    fill: Color.Green,
                }
            },
            icon: {
                width: "60%"
            }
        },
    },
    desktop: {
        width: "20.72vw",
        marginRight: "0.00vw",
        marginTop: "-20vh",
        paddingBottom: "2.77vh",
        borderRadius: "0.52vw",
        wrapper: {
            padding: "0.78vw 1.3vw 0 1.3vw",
            Color: Color.MiddleBlack,
            fontWeight: "normal",
            changeDirection: {
                flexDirection: "column",
                alignItems: "left",
                lineHeight: "1.3vw"
            },
            removePadding: {
                paddingTop: "0.28vw"
            }
        },
        logo: {
            width: "7.81vw",
            height: "7.81vw",
            marginTop: "-2.8vw",
            borderRadius: "0.52vw"
        },
        title: {
            marginTop: "0.3vw",
            fontSize: "1.05vw"
        },
        jobSaved: {
            display: "none"
        },
        organization: {
            marginTop: "-1.2vh",
            fontSize: "0.98vw"
        },
        urgentTag: {
            width: "4.42vw",
            height: "2.5vh",
            marginLeft: "0.35vw",
            borderRadius: "1.302vw",
            fontSize: "0.73vw",
            fontWeight: "normal"
        },
        videoTag: {
            width: "4.42vw",
            height: "2.5vh",
            borderRadius: "1.302vw",
            fontSize: "0.72vw",
            fontWeight: "normal"
        },
        icon: {
            width: "0.98vw",
            height: "2.24vh",
            marginLeft: "0.70vw",
            marginTop: "-1.28vh"
        },
        text: {
            fontSize: "0.83vw"
        },
        textAlign: {
            marginLeft: "-0.3vw"
        },
        remote: {
            marginLeft: "0.625vw"
        },
        smallIcon: {
            marginRight: "0.625vw",
            width: "0.78vw",
            height: "0.83vw",
            marginBottom: "-0.1vw"
        },
        briefcaseIcon: {
            width: "0.9vw",
            height: "0.9vw",
            marginBottom: "-0.1vw",
            marginRight: "0.625vw"
        },
        line: {
            backgroundColor: Color.MiddleAsh,
            margin: "1vw 1.3vw 0 1.3vw",
            border: "0.05vw solid" + Color.MiddleAsh
        },
        lineTwo: {
            marginTop: "0.4vw"
        },
        overview: {
            fontSize: "0.83vw",
            lineHeight: "1.30vw",
            fontWeight: 400,
            marginBottom: "1.04vw",
            Color: Color.MiddleBlack
        },
        summaryButtonWrapper: {
            display: "none"
        },
        flippingWrapper: {
            flexDirection: "column"
        },
        summary: {
            padding: "1.04vw 1.09vw",
            fontSize: "0.83vw",
            marginBottom: "0",
            Color: Color.MiddleBlack,
            item: {
                marginLeft: "1.04vw"
            },
            key: {
                width: "5.41vw",
                fontWeight: 400            
            },
            value: {
                padding: "0 0 1.04vw 1.04vw",
                fontWeight: 800,
                color: Color.LightBlack 
            }
        },
        apply: {
            applyButton: {
                width: "100%",
                height: "3.64vw",
                borderRadius: "0.52vw",
                fontSize: "1.04vw",
                marginTop: "0.8vw",
                boxShadow: "5px 5px 50px #00000027",
                fontWeight: 500
            },
            saveButton: {
                width: "100%",
                height: "2.604vw",
                border: "0.15625vw solid",
                borderRadius: "0.52vw",
                fontSize: "1.04vw"
            },
            saveIcon: {
                marginLeft: "0.7812vw",                
                width: "0.8vw",
                height: "2.2vh"
            },
            notifyButton: {
                marginLeft: "0.70vw",
                width: "2.6vw",
                height: "2.6vw"
            },
            rowWrapper: {
                paddingTop: "0.78vw",
                paddingLeft: "1.30vw",
                paddingRight: "1.30vw",
              },
        },
        share: {
            wrapper: {
                paddingLeft: "0.70vw",
                paddingRight: "0.73vw"
            },
            button: {
                width: "1.66vw",
                height: "1.66vw",
                marginRight: "0.35vw",
                padding: "0.43vw 0.41vw"
            },
            icon: {
                width: "0.83vw",
                height: "0.83vw"
            }
        },
    }
}