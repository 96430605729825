import { Color } from "../enums/Color";

export const shortJobCardList = {
    mobile: {
        overflow: "hidden",
        marginTop: "14.49vw",
        wrapper: { },
        zIndex: "1",
        title: {
            textAlign: "left" as "left",
            marginLeft: "4.83vw",            
            fontWeight: "bold" as "bold",            
            fontSize: "6.03vw",                    
            letterSpacing: "0px",
            color: Color.LightBlack,
            marginTop: "auto",
            marginBottom: "auto",
            opacity: "1"
        },
        redirectButton: {
            display: "none"
        },
        sliderButtonWrapper: {
            display: "none",
            flexDirection: "row" as "row", 
            marginRight: "7.29vw",
            marginLeft: "auto",
            alignItems: "center"
        },
        rightSliderButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "3.6vw",
            height: "3.6vw",
            borderRadius: "50%",
            backgroundColor: Color.Blue,
            borderColor: Color.LightBlue,
            border: "0.5vw solid " + Color.SharpBlue,
            marginLeft: "1.30vw",
            cursor: "pointer",
            fill: Color.White
        },
        buttonIcon: {
            width: "42%",
            height: "auto"
        },
        leftSliderButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "2.6vw",
            height: "2.6vw",
            borderRadius: "50%",
            backgroundColor: Color.LightAsh,
            cursor: "pointer",
            fill: Color.SharpAsh                  
        },
        slider: {
            margin: "4.83vw -4.83vw 3.2vh 4.83vw",
            width: "152.17vw"
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(1, auto)",
            margin: "4.83vw auto 0 4.83vw"
        },
        gridItem: {
            width: "77.29vw"
        },
        card: {
            width: "calc(100% - 5.5vw)",
            borderRadius: "2.41vw",
            margin: "1.2vw 0",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
            paddingTop: "4.83vw",
            paddingLeft: "3.62vw",
            paddingBottom: "3.62vw",
            hover: {
                background: Color.White,  
                boxShadow: "5px 5px 45px #00000029"
            },
            leave: {          
                background: Color.LightAsh,            
                
            },
            link: {
                textDecoration: "none",
            },
            wrapper: {
                display: "flex",
                flexDirection: "row" as "row",                
                alignItems: "center"
            },
            unalignedWrapper: {
                alignItems: "unset"
            },
            logo: {
                width: "12.07vw",
                height: "12.07vw",    
                background: Color.White,
                borderRadius: "1.20vw",
                opacity: "1",
                objectFit:"contain"
            },
            titleWrapper: {
                height: "7.35vw",
                marginTop: "1.45vw"                
            },
            title: {
                textAlign: "left" as "left",                                
                fontWeight: "bold" as "bold",                
                fontSize: "3.86vw",                    
                letterSpacing: "0px",
                color: Color.Black,
                opacity: "1",
                whiteSpace: "nowrap",
                maxWidth: "70%",
                overflow: "hidden"                
            },
            organizationWrapper: {
                height: "4.83vw"                
            },
            organization: {
                textAlign: "left" as "left",              
                fontSize: "3.62vw",
                letterSpacing: "0px",
                color: Color.Black,
                whiteSpace: "nowrap",
                maxWidth: "55.79vw",
                overflow: "hidden",
                marginRight: "2.41vw"
            },
            countDown: {
                marginLeft: "auto",
                marginRight: "3.62vw",
                marginTop: "0.97vw",
                height: "2.89vw",                                
                fontSize: "2.65vw",
                color: Color.DeepAsh ,
                display: "flex",
                alignItems: "center"
            },
            tagWrapper: {
                display: "flex",
                flexDirection: "column" as "column"
            },
            urgentTag: {
                width: "18.84vw",
                height: "4.83vw",
                marginBottom: "0.6vh",
                textAlign: "center" as "center",
                lineHeight: "4.83vw",
                borderRadius: "6.03vw 0px 0px 6.03vw",
                background: Color.Red,                
                fontSize: "2.89vw",
                color: Color.White
            },
            videoTag: {
                width: "18.84vw",
                height: "4.83vw",
                textAlign: "center" as "center",
                lineHeight: "4.83vw",
                borderRadius: "6.03vw 0px 0px 6.03vw",
                background: Color.Blue,                
                fontSize: "2.89vw",
                color: Color.White
            },    
            disableIcon: {                
                width: "3.38vw",
                height: "3.86vw",
                marginRight: "1.21vw",                
                fill: Color.Red
            },       
            cvlessIcon: {                
                width: "4.83vw",
                height: "4.83vw",
                fill: Color.Red
            },
            mediumIcon: {
                width: "3.86vw",
                height: "4.58vw",
                marginLeft: "2.77vw",
                jobSaved: {
                    fill: Color.Blue
                } 
            },
            smallIcon: {                
                width: "3.14vw",
                height: "3.14vw",
                marginRight: "1.20vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "visible"
            },
            bumpUp: {
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center",
                width: "7.35vw",
                height: "7.35vw",
                borderRadius: "50%",
                marginLeft: "2.77vw",
                backgroundColor: Color.LightRed,
                flexShrink: "0"
            },
            bumpUpIcon: {
                width: "50%"
            },
            locationWrapper: {
                marginTop: "3.02vw"
            },
            location: {
                maxWidth: "63.52vw"
            },
            jobTypeWrapper: {
                marginTop: "2.66vw"
            },
            jobType: {
                marginRight: "3.62vw"
            },
            text: {                                
                fontSize: "3.05vw",
                color: Color.DeepAsh,
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            jobSaved: {
                color: Color.Blue,
                fontSize: "3.86vw",
                marginLeft: "1.11vw",
                marginRight: "3.61vw"
            },
            saveJobWrapper: {
                display: "flex",
                alignItems: "center",
                background: "transparent"
            }
        },
        dots: { 
            marginTop: "0%", 
            marginBottom: "0%", 
            marginLeft: "-41%",
            padding: "0",
            color: Color.Black
        },
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack,
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 0 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        }
    },
    desktop: {
        marginTop: "7.81vw", 
        title: {
            fontSize: "1.56vw",
            marginLeft: "7.29vw"
        },
        wrapper: {            
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center"
        },        
        sliderButtonWrapper: {            
            display: "flex"
        },
        slider: {                        
            margin: "1.04vw -6.8vw 2.4vw 6.8vw",
            width: "171.67vw"
        },
        grid: {            
            gridTemplateColumns: "repeat(4, 21.44vw)",            
            margin: "2.60vw 6.8vw 1.82vw 6.8vw"
        },
        gridItem: {
            width: "21.562vw"
        },
        card: {
            width: "calc(100% - 1.87vw)",
            borderRadius: "0.52vw",
            margin: "0.39vw auto 0.39vw auto",
            paddingBottom: "1.56vw",
            paddingTop: "1.04vw",
            paddingLeft: "1.04vw",
            logo: {
                width: "3.9vw",
                height: "3.9vw",
                borderRadius: "0.52vw"
            },   
            titleWrapper: {
                height: "1.82vw",
                marginTop: "0.21vw"
            },    
            title: {                
                fontSize: "1.04vw"
            },
            organizationWrapper: {
                height: "1.20vw"                
            },
            organization: {                                
                fontSize: "0.93vw",
                fontWeight: "400",
                maxWidth: "15.26vw",                
                marginRight: "0.78vw"
            },
            countDown: {
                fontSize: "0.78vw",
                height: "0.88vw",
                marginRight: "0.52vw",
                marginTop: "0.13vw"
            },
            locationWrapper: {
                marginTop: "0.44vw"
            },
            location: {
                maxWidth: "17.08vw"
            },
            jobTypeWrapper: {
                marginTop: "0.31vw"
            },
            jobType: {
                marginRight: "0.78vw"
            },
            text: {
                fontSize: "0.83vw"
            },
            urgentTag: {
                width: "4.42vw",
                height: "1.19vw",
                marginBottom: "0.6vh",
                borderRadius: "1.3vw 0px 0px 1.3vw",
                fontSize: "0.72vw",
                lineHeight: "1.19vw"
            },
            videoTag: {
                width: "4.42vw",
                height: "1.19vw",
                borderRadius: "1.3vw 0px 0px 1.3vw",
                fontSize: "0.72vw",
                lineHeight: "1.19vw"
            },
            disableIcon: {                
                width: "0.87vw",
                height: "0.99vw",
                marginRight: "0.54vw"                
            },       
            cvlessIcon: {                
                width: "1.20vw",
                height: "1.20vw"                
            },
            mediumIcon: {
                width: "0.67vw",
                height: "1.041vw" ,
                marginLeft: "0.73vw"
            },
            smallIcon: {
                width: "0.78vw",
                height: "0.78vw",
                marginRight: "0.73vw",
                fontSize: "0.78vw",
                fill: Color.MiddleBlack 
            },
            bumpUp: {
                width: "1.82vw",
                height: "1.82vw",
                marginLeft: "0.73vw"
            },
            jobSaved: {
                marginLeft: "0.41vw",
                fontSize: "0.83vw",
                marginRight: "0.95vw"
            }
        },
        dots: { 
            marginLeft: "-50.2%" 
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "49.47vw",
                borderRadius: "0.7vw",
                margin: "3.2vh auto auto auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        }
    }
}