import { Color } from "../enums/Color";

export const longJobCard = {
    mobile: {                
        display: "flex",
        height: "auto",
        paddingBottom: "3.62vw",
        cursor: "pointer",
        WebkitTapHighlightColor: "transparent",
        marginTop: "3.62vw",
        position: "relative" as "relative",
        hover: { },        
        link: {
            textDecoration: "none"
        },
        rowWrapper: {
            display: "flex",
            alignItems: "center"
        },
        toggleWrapper: {
            display: "flex",
            flexDirection: "column" as "column"                                
        },
        detailWrapper: {
            width: "-webkit-fill-available",
            paddingLeft: "3.62vw"
        },
        applyWrapper: {
            display: "none"
        },
        logo: {
            width: "12.07vw",
            height: "12.07vw", 
            objectFit: "contain", 
            background: Color.White,
            borderRadius: "1.2vw",
            opacity: "1"
        },
        titleWrapper: {
            height: "7.35vw"
        },
        title: {
            height: "4.34vw",
            textAlign: "left" as "left",
            fontWeight: "bold" as "bold",                
            fontSize: "3.86vw",                    
            letterSpacing: "0px",
            color: Color.Black,
            opacity: "1",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "60vw",
            lineHeight: "4.34vw",
            marginRight: "2.59vw",
            padding: "0.54vw 0",
            jobSaved: {
                maxWidth: "50vw"
            }
        },
        bumpUp: {
            display: "flex",
            alignItems: "center" as "center",
            justifyContent: "center",
            width: "7.35vw",
            height: "7.35vw",
            borderRadius: "50%",
            marginLeft: "2.77vw",
            backgroundColor: Color.LightRed,
            flexShrink: "0"
        },
        bumpUpIcon: {
            width: "50%"
        },
        organizationWrapper: {
            height: "4.83vw",
            marginTop: "-0.73vw"                
        },
        organization: {
            display: "flex",            
            alignItems: "center",
            textAlign: "left",
            fontSize: "3.623vw",
            letterSpacing: "0px",
            color: Color.Black,
            opacity: "1",
            overflow: "hidden",
            maxWidth: "57vw",
            whiteSpace: "nowrap",
            marginRight: "2.41vw"
        },
        mobileCountDown: {
            display: "flex",
            height: "3.14vw",
            fontSize: "2.66vw",
            marginTop: "2.41vw",
            alignItems: "center",
            color: Color.DeepAsh
        },
        desktopCountDown: {
            display: "none"
        },
        topMobileTagWrapper: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        bottomMobileTagWrapper: {
            display: "none"
        },
        desktopTagWrapper: {
            display: "none"
        },
        urgentTag: {
            width: "12.07vw",
            height: "4.83vw",
            marginTop: "1.20vw",
            textAlign: "center",
            lineHeight: "4.83vw",
            borderRadius: "6.03vw",
            background: Color.Red,
            fontSize: "2.17vw",
            color: Color.White
        },
        videoTag: {
            width: "12.07vw",
            height: "4.83vw",
            marginTop: "1.20vw",
            textAlign: "center",
            lineHeight: "4.83vw",
            borderRadius: "6.03vw",
            background: Color.Blue,
            fontSize: "2.17vw",
            color: Color.White
        },
        disableIcon: {
            width: "3.38vw",
            height: "3.86vw",
            marginRight: "1.21vw",                
            fill: Color.Red,
            overflow: "visible"
        },
        cvLessIcon: {
            width: "4.83vw",
            height: "4.83vw",
            fill: Color.Red
        },
        saveIcon: {
            width: "3.14vw",
            height: "4.35vw",
            fill: Color.Blue
        },
        jobSaved: {
            color: Color.Blue,
            fontSize: "2.898vw",
            marginLeft: "1.11vw",
            marginRight: "1.93vw"
        },
        saveJobWrapper: {
            display: "flex",
            flexDirection: "row-reverse",
            marginLeft: "auto",
            alignItems: "center",
            background: "transparent"
        },
        smallIcon: {
            width: "2.89vw",
            height: "2.89vw",
            marginRight: "1.39vw",
            fill: Color.DeepAsh,
            overflow: "unset"
        },        
        text: {
            display: "flex",
            alignItems: "center",
            fontSize: "2.66vw",
            color: Color.DeepAsh,
            marginRight: "3.62vw",
            marginTop: "2.41vw"
        },
        locationIcon: {
            width: "2.65vw",
            height: "2.65vw",
            marginRight: "1.39vw",
            fill: Color.DeepAsh
        }, 
        location: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "34.30vw"
        },
        share: {
            display: "none",
            float: "inline-end",
            marginTop: "-2.70vh",
            wrapper: {
                paddingLeft: "2.78vw",
                paddingRight: "2.78vw"
            },
            button: {                
                width: "7.73vw",
                height: "7.73vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.39vw",
                backgroundColor: Color.MiddleAsh,
                border: "none",
                borderRadius: "4.44vw"
            },
            icon: {
                fill: Color.MiddleBlack,
                width: "4.44vw",
                height: "4.44vw"
            },
            hover: {
                display: "none"
            }
        },
        bottomBorder: {
            position: "absolute" as "absolute",
            bottom: "-6%",
            left: 0,
            right: 0,
            border: 0,
            height: "2px",
            background: Color.LightAsh
        }
    },
    desktop: {
        padding: "1.56vw 1.04vw",
        alignItems: "center",
        height: "7.81vw",
        marginTop: "0",
        hover: {       
            background: Color.LightAsh,            
            borderRadius: "0.52vw"
        },
        toggleWrapper: {            
            flexDirection: "row" as "row"                                
        },
        detailWrapper: {
            height: "80%",
            paddingLeft: "2.34vw",
            marginTop: "0.83vw"
        },
        logo: {
            width: "7.812vw",
            height: "7.812vw",
            borderRadius: "0.52vw"
        },
        titleWrapper: {
            height: "1.82vw"
        },
        title: {
            height: "1.19vw",
            fontSize: "1.041vw",
            lineHeight: "1.19vw",
            maxWidth: "30vw",
            marginRight: "unset",
            padding: "0.12vw 0",
            jobSaved: {
                maxWidth: "30vw"
            }
        },
        bumpUp: {
            width: "1.82vw",
            height: "1.82vw",
            marginLeft: "0.73vw"
        },
        organizationWrapper: {
            height: "1.20vw",
            margin: "0.21vw 0 0.78vw 0"                
        },
        organization: {            
            fontSize: "0.937vw",
            maxWidth: "30vw",
            marginRight: "0.78vw"
        },
        mobileCountDown: {
            display: "none" 
        },
        desktopCountDown: {
            display: "flex",
            marginLeft: "auto",
            alignItems: "center",              
            fontSize: "0.78vw",
            color: Color.DeepAsh,
            flexShrink: 0
        },
        topMobileTagWrapper: {
            display: "none"
        },
        desktopTagWrapper: {
            display: "flex",
            flexShrink: 0
        },
        urgentTag: {
            width: "6.22vw",
            height: "1.19vw",
            marginLeft: "1.46vw",
            lineHeight: "1.19vw",
            fontSize: "0.72vw",
            marginTop: "0",
            borderRadius: "1.30vw"
        },
        videoTag: {
            width: "6.22vw",
            height: "1.19vw",
            marginLeft: "1.46vw",
            lineHeight: "1.19vw",
            fontSize: "0.72vw",
            marginTop: "0",
            borderRadius: "1.30vw"
        },
        disableIcon: {
            width: "0.87vw",
            height: "0.99vw",
            marginRight: "0.54vw" 
        },
        cvLessIcon: {
            width: "1.20vw",
            height: "1.20vw" 
        },
        saveIcon: {
            width: "1.04vw",
            height: "1.04vw",
            marginLeft: "1vw"
        },
        jobSaved: {
            marginLeft: "0.2vw",
            fontSize: "0.833vw",
            marginRight: "0.95vw"
        },
        smallIcon: {                
            width: "0.78vw",
            height: "0.78vw",
            marginRight: "0.52vw"
        },
        saveJobWrapper: {
            marginLeft: "unset",
            flexDirection: "row",
        },
        text: {                
            fontSize: "0.83vw",
            marginRight: "1.82vw",
            marginTop: "0"
        },
        locationIcon: {                
            width: "0.62vw",
            height: "0.62vw",
            marginRight: "0.52vw"
        },
        location: {
            maxWidth: "35vw"
        },
        share: {
            marginTop: "0.78vw",           
            hover: {
                display: "flex"
            },
            button: {
                width: "1.66vw",
                height: "1.66vw",
                borderRadius: "1.17vw",
                marginRight: "0.36vw"
            },
            icon: {
                width: "0.83vw",
                height: "0.93vw"
            }
        },
        bottomBorder: {            
            left: "1%",
            right: "1%"
        }
    } 
}